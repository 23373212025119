<template>
  <div class="app flex-row align-items-center">
    <vue-progress-bar></vue-progress-bar>
    <div class="container" @keydown.enter="login">
      <b-row class="justify-content-center">
        <b-col md="8">
           <b-card no-body class="p-4">
             <b-card-body>
               <h1><div class="brand"></div> Password Reset</h1>

                <!-- SUBMIT EMAIL -->
                <b-container v-if="!emailSubmitted">
                  <p>
                    Forget your password? Enter your email address below and 
                    we'll send you an emial containing a code which you can use to varify your account. 
                    Then you'll be allowed to reset your password and login.
                  </p>
                  <b-row>
                    <b-col cols="12">
                      <b-form @submit="onSubmitEmail">
                        <b-form-group>
                          <b-input-group>
                            <b-input type="email" v-model="email" placeholder="email address" required></b-input>
                            <b-input-group-append>
                              <b-button type="submit" variant="info"><i class="fa fa-check"></i> Continue</b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-container>
                
                <!-- SUBMIT VALIDATION CODE -->
                <b-container v-else-if="!passTokenSubmitted">
                  <p>
                    Please check your email for a varification code (delivery may take a minute) and enter it in below.
                  </p>
                  <b-row>
                    <b-col cols="12">
                      <b-form @submit="onSubmitPassToken">
                        <b-form-group>
                          <b-input-group>
                            <b-input v-model="passToken" type="number" min="1000" max="9999" placeholder="varification code" required></b-input>
                            <b-input-group-append>
                              <b-button type="submit" variant="info"><i class="fa fa-check"></i> Continue</b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-container>
                
                <!-- SUBMIT PASSWORD -->
                <b-container v-else>
                  <p>
                    Plese use the fields below below to reset your password.
                  </p>
                  <b-row>
                    <b-col cols="12">
                      <b-form @submit="onSubmitPassword">
                        <b-form-group
                          label="Password"
                          label-for="input-password"
                          label-sr-only
                        >
                          <b-form-input class="form-control input-lg" v-model="plainPassword.first" placeholder="Password" maxlength="4096" id="input-password" value="" type="password" />
                        </b-form-group>
                        <b-form-group
                          label="Confirm Password"
                          label-for="input-password2"
                          label-sr-only
                        >
                          <b-form-input class="form-control input-lg" :state="passwordsMatch" v-model="plainPassword.second" placeholder="Confirm Password" maxlength="4096" id="input-password2" value="" type="password" />
                        </b-form-group>
                        <div>
                          <b-button type="submit" variant="success"><i class="fa fa-check"></i> Update My Password</b-button>
                        </div>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-container>
                  <p class="float-right"><b-button variant="link" class="p-0 pr-3" :to="{ name: 'login' }">Back to Login</b-button></p>

            </b-card-body>
           </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  layout: 'site-main',
  head () {
    return {
      title: 'Password Reset'
    }
  },
  data () {
    return {
      email: null,
      emailSubmitted: false,
      passToken: null,
      passTokenSubmitted: false,
      plainPassword: {
        first: '',
        second: ''
      },
      userData: {}
    }
  },
  computed: {
    passwordsMatch () {
      if (this.plainPassword.first !== this.plainPassword.second) {
        return false
      }
    }
  },
  methods: {
    ...mapActions(['resetPassword']),
    onSubmitEmailValidate () {
      if (this.email === '') {
        this.$toast.error('You must submit a registered email address')
        return false
      }
      return true
    },
    async onSubmitEmail (e) {
      e.preventDefault()
      if (!this.onSubmitEmailValidate) return
      // @TODO ini loading
      // request token
      try {
        await this.$recaptchaLoaded()
        let captcha = await this.$recaptcha('admin_user_password_reset')
        await this.resetPassword({
          email: this.email,
          captcha
        })
      } catch (e) {
        console.log(e)
        this.showError("There was an error finding that email")
        // @TODO complete loading
        return
      }

      // @TODO complete loading
      this.emailSubmitted = true
    },
    onSubmitPassTokenValidate () {
      if (isNaN(passToken) || passToken.length > 4) {
        this.showError('Please submit a valid password token')
        return false
      }
      return true
    },
    async onSubmitPassToken (e) {
      e.preventDefault()
      if (!this.onSubmitPassTokenValidate) return
      // @TODO ini loading
      // confirm token
      try {
        await this.$recaptchaLoaded()
        let captcha = await this.$recaptcha('admin_user_password_reset')
        await this.resetPassword({
          email: this.email,
          passToken: this.passToken,
          captcha
        })
      } catch (e) {
        console.log(e)
        this.showError("there was an error validating your code.")
        // @TODO complete loading
        return
      }

      // @TODO complete loading
      this.passTokenSubmitted = true
    },
    onSubmitPasswordValidate () {
      if (!this.passwordsMatch) {
        this.showError('Your passwords do not match')
        return false
      }
    },
    async onSubmitPassword (e) {
      e.preventDefault()
      if (!this.onSubmitPasswordValidate) return
      // update password
      await this.$recaptchaLoaded()
      let captcha = await this.$recaptcha('admin_user_password_reset')
      await this.resetPassword({
        email: this.email,
        passToken: this.passToken,
        plainPassword: this.plainPassword,
        captcha
      })
      this.showSuccess('Your password has been updated.')
      this.$router.push({name: 'login'})
    }
  }
}
</script>